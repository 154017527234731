import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isArray, isString } from 'lodash';
import {
  catchError,
  EMPTY,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { FleetIdentifierPipe } from '../../../../common/components/pipes/fleet-identifier.pipe';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { FleetService } from '../../../fleet/common/services/fleet.service';
import { DriverForm } from '../../driver-form/driver-form.component';
import {
  DriverModalComponent,
  DriverModalData,
} from '../../driver-modal/driver-modal.component';
import { DriversRoute } from '../../drivers.routes';
import {
  DriverAssignVehicleModalComponent,
  DriverAssignVehicleModalData,
} from '../components/driver-assign-vehicle-modal/driver-assign-vehicle-modal.component';
import { Driver, SimplifiedDriver } from '../interfaces/driver';
import { DriverCategory } from '../interfaces/driver-category';
import { DriverStatus } from '../interfaces/driver-status';
import { DriversService } from '../services/drivers.service';

@Injectable({
  providedIn: 'root',
})
export class DriverHelper {
  private readonly I18N = 'drivers.driversList';

  constructor(
    private driversService: DriversService,
    private router: Router,
    private modalService: OnyxModalService,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private fleetService: FleetService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
  ) {}

  public getDriversSource(): OnyxDropdownOptionsSource<Driver> {
    return {
      list: (query, limit) =>
        this.driversService.searchDrivers(query, limit, {
          category: DriverCategory.ACTIVE,
        }),
      get: (uuid) =>
        this.driversService.getDriver(uuid).pipe(
          map((driver) => ({
            name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
            value: driver,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    driver: Driver,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const isArchived = DriverHelper.isArchived(driver);
    return [
      {
        options: [
          ...(!isArchived
            ? [
                {
                  name: 'buttons.edit',
                  value: () => this.edit(driver.uuid, options),
                },
              ]
            : []),
        ],
      },
      {
        options: [
          ...(!isArchived
            ? [
                {
                  name: `${this.I18N}.assignVehicle`,
                  value: () =>
                    this.modalService.open<DriverAssignVehicleModalData>(
                      DriverAssignVehicleModalComponent,
                      driver,
                    ),
                },
              ]
            : []),
          ...(driver.assignedVehicle
            ? [
                {
                  name: `${this.I18N}.unassignVehicles`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignVehicles`,
                          )} ${driver.driverData.firstName} ${
                            driver.driverData.lastName
                          }?`,
                          confirmButtonText: `${this.I18N}.unassignVehicles`,
                        },
                      )
                      .subscribe((ok) => {
                        if (!ok) return;

                        this.unassignVehicle(
                          driver.uuid,
                          driver.assignedVehicle!.uuid,
                        );
                      }),
                },
              ]
            : []),
        ],
      },
      {
        options: [
          {
            name: isArchived ? 'buttons.unarchive' : 'buttons.archive',
            value: () => this.toggleArchived(driver, options),
          },
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(
    driver: DriverModalData['driver'],
    size: DriverModalData['size'] = 'm',
  ): void {
    this.modalService.open<DriverModalData>(DriverModalComponent, {
      driver,
      size,
    });
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${DriversRoute.EDIT_DRIVER.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public toggleArchived(
    drivers: Driver | Driver[],
    options: HelperOptions,
    force?: boolean,
  ): void {
    const items = chain(drivers)
      .thru((drivers) => (isArray(drivers) ? drivers : [drivers]))
      .groupBy((driver) => force ?? !DriverHelper.isArchived(driver))
      .thru((groups) => ({
        archive: groups['true'] ?? [],
        unarchive: groups['false'] ?? [],
      }))
      .value();
    const showConfirmModal = items.archive.some(
      (driver) => driver.assignedVehicle,
    );

    const toggle$ = (items: Driver[], force: boolean): Observable<void> => {
      if (!items.length) return of(undefined);

      return this.driversService
        .batchDrivers(
          items.map((item) => item.uuid),
          { ...(force ? { archive: true } : { unarchive: true }) },
        )
        .pipe(
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return EMPTY;
          }),
          tap(() => {
            this.toastService.showSuccess(
              this.translateService.instant(
                `drivers.toasts.${items.length === 1 ? 'driver' : 'drivers'}${force ? 'Archived' : 'Unarchived'}`,
                { count: items.length },
              ),
            );
          }),
        );
    };
    const getName = (driver: Driver) => {
      const { firstName, lastName } = driver.driverData;
      return `${firstName} ${lastName}`;
    };

    of(items)
      .pipe(
        switchMap((items) => {
          if (showConfirmModal) {
            return this.modalService
              .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
                heading: this.translateService.instant(
                  `${this.I18N}.sureToArchive${items.archive.length === 1 ? 'Driver' : 'Drivers'}`,
                  {
                    name: getName(items.archive[0]),
                    vehicle: this.fleetIdentifierPipe.transform(
                      items.archive[0].assignedVehicle,
                    ),
                  },
                ),
                badges:
                  items.archive.length > 1
                    ? items.archive.map((driver) => ({
                        value: getName(driver),
                        color: 'outlined-black',
                      }))
                    : undefined,
                message: `${this.I18N}.sureToContinueArchive`,
                confirmButtonText: 'buttons.archive',
              })
              .pipe(switchMap((ok) => (ok ? of(items) : EMPTY)));
          }
          return of(items);
        }),
        switchMap((items) =>
          forkJoin([
            toggle$(items.archive, true),
            toggle$(items.unarchive, false),
          ]),
        ),
      )
      .subscribe(() => CommonHelper.handleOptions(drivers, options));
  }

  private unassignVehicle(driver: string, vehicle: string): void {
    this.fleetService.unassignDrivers(vehicle, driver).subscribe({
      next: () => {
        this.toastService.showSuccess(`${this.I18N}.vehicleUnassigned`);
        this.driversService.reload();
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  public static isActive(category: DriverCategory): boolean {
    return category === DriverCategory.ACTIVE;
  }

  public static isArchived(driver: Driver | DriverStatus): boolean {
    const status = isString(driver) ? driver : driver.status.status;
    return status === DriverStatus.ARCHIVED;
  }

  public static isDriverType(
    driver: SimplifiedDriver | Driver,
  ): driver is Driver {
    return 'employmentConditions' in driver;
  }

  public static fromDto(dto: Driver): DriverForm {
    return {
      ...dto,
      driverData: {
        ...dto.driverData,
        assignedBase: dto.driverData.assignedBase?.uuid ?? null,
      },
      employmentConditions: {
        ...dto.employmentConditions,
        mileageRate: dto.employmentConditions
          .netMileagePatePerKm as DriverForm['employmentConditions']['mileageRate'],
        dailyRate: dto.employmentConditions
          .dailyRate as DriverForm['employmentConditions']['dailyRate'],
        baseRate: dto.employmentConditions
          .baseRate as DriverForm['employmentConditions']['baseRate'],
        roundTripRate: dto.employmentConditions
          .roundTripRate as DriverForm['employmentConditions']['roundTripRate'],
        scans: dto.employmentConditions
          .scans as DriverForm['employmentConditions']['scans'],
      },
      driversLicenseAndProfessionalQualifications: {
        ...dto.driversLicenseAndProfessionalQualifications,
        selectedCategories:
          dto.driversLicenseAndProfessionalQualifications.categories.map(
            (category) => category.category,
          ),
        scans: dto.driversLicenseAndProfessionalQualifications
          .scans as DriverForm['driversLicenseAndProfessionalQualifications']['scans'],
      },
      driverCard: dto.driverCard as DriverForm['driverCard'],
      identityDocuments: {
        hasDocuments: true,
        passport: dto.identityDocuments
          .passport as DriverForm['identityDocuments']['passport'],
        identityDocument: dto.identityDocuments
          .identityDocument as DriverForm['identityDocuments']['identityDocument'],
        residenceCard: dto.identityDocuments
          .residenceCard as DriverForm['identityDocuments']['residenceCard'],
        polishCard: dto.identityDocuments
          .polishCard as DriverForm['identityDocuments']['polishCard'],
      },
      clearCriminalRecordCertificate: {
        ...dto.clearCriminalRecordCertificate,
        scans: dto.clearCriminalRecordCertificate
          .scans as DriverForm['clearCriminalRecordCertificate']['scans'],
      },
      visas: dto.visas.map((visa) => ({
        ...visa,
        scans: visa.scans as DriverForm['visas'][number]['scans'],
      })),
      permissions: dto.permissions.map((permission) => ({
        ...permission,
        scans: permission.scans as DriverForm['permissions'][number]['scans'],
      })),
    };
  }
}
