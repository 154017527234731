@if (groups()) {
  <ul
    class="container"
    [ngClass]="{
      multiple: config.multiple,
      optional: config.optional || config.multiple,
    }"
    cdkListbox
    [cdkListboxMultiple]="config.multiple"
    [cdkListboxNavigationWrapDisabled]="true"
  >
    <div class="header">
      @if (config.search) {
        <onyx-search
          class="search"
          [(query)]="config.query"
          [placeholder]="config.searchPlaceholder"
          color="white"
          [showFocus]="false"
          size="s"
          (keydown.arrowup)="focusElement(); $event.preventDefault()"
          (keydown.arrowdown)="focusFirstOption(); $event.preventDefault()"
        ></onyx-search>

        @if (!config.multiple) {
          <div class="separator"></div>
        }
      }

      @if (!isEmpty() && config.multiple) {
        <button
          class="item {{ config.optionSize }}"
          cdkOption="_toggleAll"
          (click)="toggleAll()"
          (keydown.space)="toggleAll()"
          (keydown.enter)="toggleAll()"
          (keydown.arrowup)="focusSearch(); $event.preventDefault()"
        >
          <span>
            {{
              I18N + (selectedAll() ? '.deselectAll' : '.selectAll') | translate
            }}
          </span>
        </button>

        <div class="separator"></div>
      }
    </div>

    <div class="options">
      @if (!isEmpty()) {
        @for (
          group of groups();
          track $index;
          let firstGroup = $first;
          let lastGroup = $last
        ) {
          @if (group.subheadingTemplateRef) {
            <ng-container
              *ngTemplateOutlet="group.subheadingTemplateRef"
            ></ng-container>
          } @else if (group.subheading) {
            <p class="subheading f-regular-3">
              {{ group.subheading | translate }}
            </p>
          } @else if (!firstGroup) {
            <div class="separator"></div>
          }

          @for (option of group.options; track option.value) {
            <button
              class="item {{ config.optionSize }}"
              [ngClass]="{
                disabled: option.disabled,
                selected: option._selected,
              }"
              [cdkOption]="option.value"
              [cdkOptionDisabled]="option.disabled"
              [onyxTooltip]="option.tooltip"
              [onyxTooltipPositions]="
                option.tooltipPosition ?? [OnyxOverlayPosition.RIGHT]
              "
              [onyxTooltipArrowOffsets]="{ y: -4 }"
              (click)="toggleOption(option.value)"
              (keydown.space)="toggleOption(option.value)"
              (keydown.enter)="toggleOption(option.value)"
              (keydown.arrowup)="
                firstGroup && $first && !config.multiple ? focusSearch() : null
              "
            >
              @if (option.leftIcon) {
                <onyx-icon
                  [class]="option.leftIconColor ?? ''"
                  [name]="option.leftIcon.name"
                  [size]="option.leftIcon.size"
                  [frame]="16"
                ></onyx-icon>
              }

              <ng-container
                *ngTemplateOutlet="
                  config.optionTemplateRef ?? optionTemplate;
                  context: {
                    option,
                    query: config.query(),
                    disabled: option.disabled,
                    selected: option._selected,
                  }
                "
              ></ng-container>

              @if (option.hint) {
                <span class="alt f-regular-3">{{ option.hint }}</span>
              }

              @if (option.rightIcon) {
                <onyx-icon
                  [class]="option.rightIconColor ?? ''"
                  [ngClass]="{ hidden: option._selected }"
                  [name]="option.rightIcon.name"
                  [size]="option.rightIcon.size"
                  [frame]="16"
                ></onyx-icon>
              }

              <onyx-icon
                class="check"
                [ngClass]="{ hidden: !option._selected }"
                name="check"
                [size]="12"
                [frame]="16"
              ></onyx-icon>
            </button>
          }
        }
      } @else {
        <div class="item {{ config.optionSize }} empty f-italic">
          {{ config.notFoundMessage ?? I18N + '.notFound' | translate }}
        </div>
      }
    </div>

    <div class="footer">
      @if (config.showAddOption) {
        @let query = config.query() ?? '';
        @if (query || config.showAddOption !== 'value') {
          @if (!isEmpty()) {
            <div class="separator"></div>
          }

          <button
            class="add item {{ config.optionSize }}"
            cdkOption="_add"
            (click)="config.addOption?.(query); config.close()"
            (keydown.space)="config.addOption?.(query); config.close()"
            (keydown.enter)="config.addOption?.(query); config.close()"
          >
            {{ config.addOptionMessage ?? I18N + '.add' | translate }}
            @if (query) {
              "{{ query }}"
            }
          </button>
        }
      }

      @if ((config.multiple || config.optional) && selectedAny()) {
        <div class="separator"></div>

        <button
          class="clear item {{ config.optionSize }}"
          cdkOption="_clear"
          (click)="clear()"
          (keydown.space)="clear()"
          (keydown.enter)="clear()"
        >
          <onyx-icon name="close" [size]="12" [frame]="16"></onyx-icon>

          <span>{{ I18N + '.clear' | translate }}</span>
        </button>
      }
    </div>
  </ul>
} @else {
  <onyx-spinner></onyx-spinner>
}

<ng-template let-option="option" let-query="query" #optionTemplate>
  <div class="content">
    <p class="name">
      <span [innerHTML]="option.name | translate | onyxHighlight: query"></span>

      @if (option.count) {
        <span class="alt f-medium-3">{{ option.count | number }}</span>
      }
    </p>

    @if (option.description) {
      <p class="alt f-regular-3">{{ option.description }}</p>
    }
  </div>
</ng-template>
