<onyx-modal
  type="center"
  size="s"
  [heading]="
    (isActivate
      ? 'delegations.activateDelegation'
      : 'delegations.finishDelegation'
    ) | translate
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    <div class="details">
      @let delegation = data.delegation;
      <div class="wrapper gray">
        <p>{{ 'labels.id' | translate }}</p>

        <p>
          {{
            (delegation.secondaryDriver ? 'labels.drivers' : 'labels.driver')
              | translate
          }}
        </p>

        <p>{{ 'labels.vehicle' | translate }}</p>
      </div>

      <div class="wrapper f-medium-2">
        <p>{{ delegation.id }}</p>

        <p>{{ drivers() | join: ',' : 2 : null }}</p>

        <p>{{ delegation.vehicle | fleetIdentifier }}</p>
      </div>
    </div>

    <div class="separator"></div>

    <form [formGroup]="form">
      <div class="group">
        <div class="item">
          <onyx-datepicker
            formControlName="date"
            [label]="'labels.date' | translate"
            [yearsRange]="{ past: 1, future: 1 }"
          ></onyx-datepicker>

          <onyx-suggestions
            [control]="form.controls.date"
            [suggestions]="DATE_SUGGESTIONS"
          ></onyx-suggestions>
        </div>

        <div class="item">
          <onyx-timepicker
            formControlName="time"
            [label]="'labels.hour' | translate"
          ></onyx-timepicker>

          <onyx-suggestions
            [control]="form.controls.time"
            [suggestions]="TIME_SUGGESTIONS"
          ></onyx-suggestions>
        </div>
      </div>

      <onyx-address-input
        formControlName="address"
        [label]="'labels.address' | translate"
        [showApartment]="true"
        apartmentWidth="100px"
      ></onyx-address-input>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button color="black" type="outlined" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ (isActivate ? 'buttons.activate' : 'buttons.finish') | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
