<div class="input">
  @if (label(); as label) {
    <onyx-input-label
      [disabled]="disabled()"
      [optional]="isOptional() || !!forceOptional()"
      [label]="label"
      [hint]="hint()"
      [link]="link()"
      (click)="focus()"
    ></onyx-input-label>
  }

  <div
    class="container {{ size() }}"
    [ngClass]="{ disabled: disabled(), invalid: isInvalid(), active: value }"
    tabindex="-1"
    (click)="focus()"
    (keydown)="(undefined)"
  >
    <input
      [type]="computedType()"
      [disabled]="disabled()"
      [attr.autocomplete]="autocomplete()"
      [attr.placeholder]="placeholder()"
      (keypress)="validateInput($event)"
      (paste)="validatePaste($event)"
      (input)="handleValueChange($event)"
      (focusout)="onTouched?.()"
      #inputElement
    />

    <div class="options">
      <div class="actions">
        @if (value && !disabled()) {
          <ng-content select="[action]"></ng-content>

          @if (showClear()) {
            <onyx-clear-button (click)="clearInput()"></onyx-clear-button>
          }
        }
      </div>

      <ng-content select="[option]"></ng-content>

      @if (showPassword() != null) {
        <onyx-icon-button
          type="transparent"
          color="black"
          size="xs"
          [tabindex]="-1"
          [disabled]="disabled()"
          (click)="toggleShowPassword()"
        >
          <onyx-icon
            class="visibility"
            [name]="showPassword() ? 'visibility-hidden' : 'visibility-shown'"
            [size]="16"
          ></onyx-icon>
        </onyx-icon-button>
      }

      @if (unit()) {
        <p class="hint">{{ unit() }}</p>
      }

      @if (maxLengthHint()) {
        <p class="hint">{{ maxLengthHint() }}</p>
      }
    </div>
  </div>

  @if (!disabled()) {
    <ng-content></ng-content>
  }
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [formControls]="[formControl()!]"
  ></onyx-form-control-error>
}
