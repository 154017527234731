<div class="container">
  @let driverCard = driver().driverCard;
  @if (driverCard) {
    <app-modal-documents-heading
      heading="labels.driverCard"
      [scans]="driverCard.scans"
    ></app-modal-documents-heading>

    <app-modal-date-row
      label="labels.expirationDate"
      [expirationDate]="driverCard.expirationDate"
      [size]="size()"
    ></app-modal-date-row>

    <onyx-information-row [label]="'labels.number' | translate" [size]="size()">
      {{ driverCard.number }}
    </onyx-information-row>

    <onyx-information-row
      [label]="'labels.issuingCountry' | translate"
      [size]="size()"
    >
      <app-country-cell
        [countryCode]="driverCard.issuingCountry"
      ></app-country-cell>
    </onyx-information-row>
  }

  @let driverLicense = driver().driversLicenseAndProfessionalQualifications;
  <app-modal-documents-heading
    heading="labels.driverLicense"
    [scans]="driverLicense.scans"
  ></app-modal-documents-heading>

  @for (category of driverLicense.categories; track category) {
    <onyx-information-row
      [label]="
        DictionaryCode.DRIVERS_LICENSE_CATEGORY + '.' + category.category
          | translate
      "
      [size]="size()"
    >
      @if (category.expirationDate.date) {
        {{ 'labels.to' | translate | lowercase }}
        {{ category.expirationDate.date | onyxDate: 'date-dot' }}
      } @else if (category.expirationDate.indefinite) {
        {{ 'labels.indefinite' | translate }}
      }
    </onyx-information-row>
  }

  @if (driverLicense.professionalQualificationsExpirationDate) {
    <app-modal-date-row
      label="labels.professionalQualifications"
      [expirationDate]="driverLicense.professionalQualificationsExpirationDate"
      [size]="size()"
    ></app-modal-date-row>
  }

  <onyx-information-row [label]="'labels.number' | translate" [size]="size()">
    {{ driverLicense.driversLicenseNumber }}
  </onyx-information-row>

  @let passport = driver().identityDocuments.passport;
  @if (passport) {
    <app-modal-documents-heading
      heading="labels.passport"
      [scans]="passport.scans"
    ></app-modal-documents-heading>

    <app-modal-date-row
      label="labels.expirationDate"
      [expirationDate]="passport.expirationDate"
      [size]="size()"
    ></app-modal-date-row>

    <onyx-information-row [label]="'labels.number' | translate" [size]="size()">
      {{ passport.number }}
    </onyx-information-row>

    <onyx-information-row
      [label]="'labels.issuingCountry' | translate"
      [size]="size()"
    >
      <app-country-cell
        [countryCode]="passport.issuingCountry"
      ></app-country-cell>
    </onyx-information-row>
  }

  @let identityDocument = driver().identityDocuments.identityDocument;
  @if (identityDocument) {
    <app-modal-documents-heading
      heading="labels.identityDocument"
      [scans]="identityDocument.scans"
    ></app-modal-documents-heading>

    <app-modal-date-row
      label="labels.expirationDate"
      [expirationDate]="identityDocument.expirationDate"
      [size]="size()"
    ></app-modal-date-row>

    <onyx-information-row [label]="'labels.number' | translate" [size]="size()">
      {{ identityDocument.number }}
    </onyx-information-row>

    <onyx-information-row
      [label]="'labels.issuingCountry' | translate"
      [size]="size()"
    >
      <app-country-cell
        [countryCode]="identityDocument.issuingCountry"
      ></app-country-cell>
    </onyx-information-row>
  }

  @let clearCriminalRecordCertificate = driver().clearCriminalRecordCertificate;
  <app-modal-documents-heading
    heading="labels.clearCriminalRecordCertificate"
    [scans]="clearCriminalRecordCertificate.scans"
  ></app-modal-documents-heading>

  <onyx-information-row
    [label]="'labels.issueDate' | translate"
    [size]="size()"
  >
    {{ clearCriminalRecordCertificate.issuingDate | onyxDate: 'date-dot' }}
  </onyx-information-row>

  @let employment = driver().employmentConditions;
  <app-modal-documents-heading
    [heading]="DictionaryCode.CONTRACT_TYPE + '.' + employment.contractType"
    [scans]="employment.scans"
  ></app-modal-documents-heading>

  <onyx-information-row
    [label]="'labels.driverSettlementMethod' | translate"
    [size]="size()"
  >
    {{
      DictionaryCode.DRIVER_SETTLEMENT_METHOD +
        '.' +
        employment.settlementMethod | translate
    }}
  </onyx-information-row>

  @if (employment.dailyRate) {
    <onyx-information-row [label]="'labels.rate' | translate" [size]="size()">
      <app-amount-cell [amount]="employment.dailyRate"></app-amount-cell>
    </onyx-information-row>
  } @else if (employment.netMileagePatePerKm) {
    <onyx-information-row
      [label]="'labels.mileageRate' | translate"
      [size]="size()"
    >
      <app-amount-cell
        [amount]="employment.netMileagePatePerKm"
      ></app-amount-cell>
    </onyx-information-row>
  } @else if (employment.baseRate && employment.freightPercentage) {
    <onyx-information-row
      [label]="'labels.baseRate' | translate"
      [size]="size()"
    >
      <app-amount-cell [amount]="employment.baseRate"></app-amount-cell>
    </onyx-information-row>

    <onyx-information-row
      [label]="'labels.freightPercentage' | translate"
      [size]="size()"
    >
      {{ employment.freightPercentage }}%
    </onyx-information-row>
  }

  @if (employment.contractType === ContractType.B2B) {
    <onyx-information-row [label]="'labels.vatId' | translate" [size]="size()">
      {{ employment.nipNumber }}
    </onyx-information-row>

    <onyx-information-row
      [label]="'labels.company' | translate"
      [size]="size()"
    >
      {{ employment.companyName }}
    </onyx-information-row>
  }

  <onyx-information-row [label]="'labels.range' | translate" [size]="size()">
    @let from = employment.contractTerm.from;
    @let to = employment.contractTerm.to;

    @if (from && to) {
      {{ from | onyxDate: 'date-dot' : to }}
    } @else {
      -
    }
  </onyx-information-row>

  @let visas = driver().visas;
  @for (visa of visas; track visa) {
    <app-modal-documents-heading
      [heading]="DictionaryCode.COUNTRY + '.' + visa.country"
      subheading="labels.visa"
      [scans]="visa.scans"
    ></app-modal-documents-heading>

    <app-modal-date-row
      label="labels.expirationDate"
      [expirationDate]="visa.expirationDate"
      [size]="size()"
    ></app-modal-date-row>
  }

  @for (permission of driver().permissions; track permission) {
    <app-modal-documents-heading
      [heading]="
        DictionaryCode.DRIVER_PERMISSION_TYPE + '.' + permission.permissionType
      "
      [scans]="permission.scans"
    ></app-modal-documents-heading>

    @if (permission.expirationDate) {
      <app-modal-date-row
        label="labels.expirationDate"
        [expirationDate]="permission.expirationDate"
        [size]="size()"
      ></app-modal-date-row>
    }
  }
</div>
