<onyx-modal
  type="center"
  size="m"
  heading="{{ fleet | fleetIdentifier }} - {{ I18N + '.heading' | translate }}"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <onyx-icon
    headingIcon
    class="vehicle-icon"
    [name]="$any(fleet.generalInformation.category)"
    [size]="28"
  ></onyx-icon>

  <div class="container">
    <form [formGroup]="form">
      <div class="item">
        <onyx-dropdown
          formControlName="primaryDriverUuid"
          [label]="I18N + '.firstDriver' | translate"
          [options]="DRIVERS_SOURCE"
          [optional]="true"
          (selectedValueChange)="primaryDriver.set($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!primaryDriver()"
          (click)="openDriverModal(primaryDriver())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>

      <div class="item">
        <onyx-dropdown
          formControlName="secondaryDriverUuid"
          [label]="I18N + '.secondDriver' | translate"
          [options]="DRIVERS_SOURCE"
          [optional]="true"
          (selectedValueChange)="secondaryDriver.set($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!secondaryDriver()"
          (click)="openDriverModal(secondaryDriver())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>
    </form>

    @for (swappedData of swappedData(); track swappedData.driver.uuid) {
      <onyx-message size="s" type="warning" [borderRadius]="true">
        <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

        <p>
          @let driverData = swappedData.driver.driverData;
          @let vehicle = swappedData.vehicle;

          {{ 'labels.driver' | translate }}

          <span class="f-medium-2">
            {{ driverData.firstName }} {{ driverData.lastName }}
          </span>

          {{ I18N + '.willBeUnnassigned' | translate }}

          <span class="f-medium-2">{{ vehicle | fleetIdentifier }}</span>
        </p>
      </onyx-message>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
