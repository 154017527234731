@let generalInformation = vehicles[0].generalInformation;
@let singleHeadingTemplate =
  (generalInformation | fleetIdentifier) +
  ' - ' +
  (I18N + '.singleHeading' | translate);
<onyx-modal
  type="center"
  size="m"
  [heading]="
    isSingle() ? singleHeadingTemplate : (I18N + '.bulkHeading' | translate)
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <onyx-icon
    headingIcon
    class="vehicle-icon"
    [name]="$any(generalInformation.category)"
    [size]="28"
  ></onyx-icon>

  <div class="container">
    @if (!isSingle()) {
      <div class="vehicles">
        <p>
          {{
            I18N + '.changingAssignedEmployees'
              | translate: { count: vehicles.length }
          }}
        </p>

        <div class="badges">
          @for (vehicle of vehicles; track vehicle) {
            <onyx-badge color="outlined-black" size="m">
              {{ vehicle | fleetIdentifier }}
            </onyx-badge>
          }
        </div>
      </div>
    }

    <form [formGroup]="form">
      <div class="item">
        <onyx-dropdown
          formControlName="employees"
          [label]="'labels.employees' | translate"
          [options]="EMPLOYEES_SOURCE"
          [multiple]="true"
          (selectedValueChange)="employee.set($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!employee()"
          (click)="openModal(employee())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
