import { Routes } from '@angular/router';
import { dashboardGuard } from './common/guards/dashboard.guard';
import { DELEGATIONS_ROUTES } from './delegations/delegations.routes';
import { DRIVERS_ROUTES } from './drivers/drivers.routes';
import { FLEET_ROUTES } from './fleet/fleet.routes';
import { MANAGEMENT_PANEL_ROUTES } from './management-panel/management-panel.routes';
import { SETTINGS_ROUTES } from './settings/settings.routes';

export enum DashboardRoute {
  DASHBOARD = '/dashboard',
  ORDERS = '/orders',
  FLEET = '/fleet',
  DRIVERS = '/drivers',
  OPTIMIZATION = '/optimization',
  ANALYTICS = '/analytics',
  REPAIRS = '/repairs',
  TASKS = '/tasks',
  ERRANDS = '/errands',
  DELEGATIONS = '/delegations',
  DISTRIBUTION = '/distribution',
  SETTLEMENTS = '/settlements',
  MAP = '/map',
  MANAGEMENT_PANEL = '/management-panel',
  SETTINGS = '/settings',
}

export const DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    canActivate: [dashboardGuard()],
    loadComponent: () =>
      import(
        './common/components/dashboard-interface/dashboard-interface.component'
      ).then((m) => m.DashboardInterfaceComponent),
    children: [
      {
        path: 'dashboard',
        redirectTo: DashboardRoute.DELEGATIONS,
      },
      // {
      //   path: 'orders',
      //   children: ORDERS_ROUTES,
      // },
      {
        path: 'fleet',
        children: FLEET_ROUTES,
      },
      {
        path: 'drivers',
        children: DRIVERS_ROUTES,
      },
      // {
      //   path: 'map',
      //   children: MAP_ROUTES,
      // },
      {
        path: 'delegations',
        children: DELEGATIONS_ROUTES,
      },
      {
        path: 'management-panel',
        children: MANAGEMENT_PANEL_ROUTES,
      },
      {
        path: 'settings',
        children: SETTINGS_ROUTES,
      },
    ],
  },
] as const;
