@if (businessHours(); as businessHours) {
  <div class="opening-hours">
    <div class="labels f-regular-3">
      <p>{{ 'labels.weekdayShortcut' | translate }}:</p>
      <p>{{ 'labels.saturdayShortcut' | translate }}:</p>
      <p>{{ 'labels.sundayShortcut' | translate }}:</p>
    </div>

    <div class="hours f-medium-3">
      <p>{{ businessHours.weekday | onyxTime }}</p>
      <p>
        {{
          businessHours.saturday
            ? (businessHours.saturday | onyxTime)
            : ('labels.closed' | translate)
        }}
      </p>
      <p>
        {{
          businessHours.sunday
            ? (businessHours.sunday | onyxTime)
            : ('labels.closed' | translate)
        }}
      </p>
    </div>
  </div>
} @else {
  -
}
