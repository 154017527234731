<onyx-modal
  type="right"
  size="m"
  [heading]="delegation() ? 'DEL-N/A' : ''"
  [loading]="loading()"
  [(error)]="error"
  [extend]="true"
  [close$]="close$"
  (errorChange)="delegation$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container headingLeftOptions>
    @if (delegation()) {
      <h3 class="delegation">
        {{ 'labels.delegation' | translate }}
      </h3>
    }
  </ng-container>

  <ng-container rightOptions>
    @if (delegation(); as delegation) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [disabled]="!options()?.length"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        [onyxTooltip]="'labels.options' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (hasEdit()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          [onyxTooltip]="'buttons.edit' | translate"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      @if (headerActionButton(); as button) {
        <onyx-button size="s" (click)="button.value()">
          {{ button.name | translate }}
        </onyx-button>
      }
    }
  </ng-container>

  <ng-container bottomOptions>
    @if (delegation(); as delegation) {
      <app-delegation-modal-heading
        [primaryDriver]="delegation.primaryDriver"
        [secondaryDriver]="delegation.secondaryDriver"
      >
      </app-delegation-modal-heading>
    }
  </ng-container>

  @if (delegation(); as delegation) {
    <div class="container">
      <app-delegation-modal-main-section
        [delegation]="delegation"
        [close$]="close$"
      >
      </app-delegation-modal-main-section>

      <div class="chips-container">
        <onyx-chips
          class="chips"
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>

        @switch (group()[0]) {
          @case (DelegationModalSection.DOCUMENTS) {
            N/A
          }

          @case (DelegationModalSection.EXPENSES) {
            N/A
          }

          @case (DelegationModalSection.FINANCES) {
            N/A
          }

          @case (DelegationModalSection.ORDERS) {
            N/A
          }

          @case (DelegationModalSection.TACHOGRAPH) {
            N/A
          }
        }
      </div>
    </div>
  }
</onyx-modal>
