import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OnyxConfirmModalComponent,
  OnyxConfirmModalData,
  OnyxDropdownOptionsSource,
  OnyxModalService,
  OnyxOptionsGroup,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isArray, isString } from 'lodash';
import {
  catchError,
  concatMap,
  EMPTY,
  forkJoin,
  from,
  last,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { PickDeep } from 'type-fest';
import { FleetIdentifierPipe } from '../../../../common/components/pipes/fleet-identifier.pipe';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { FleetForm } from '../../fleet-form/fleet-form.component';
import {
  FleetModalComponent,
  FleetModalData,
} from '../../fleet-modal/fleet-modal.component';
import { FleetRoute } from '../../fleet.routes';
import {
  FleetAssignDriverModalComponent,
  FleetAssignDriverModalData,
} from '../components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import {
  FleetAssignEmployeesModalComponent,
  FleetAssignEmployeesModalData,
} from '../components/fleet-assign-employees-modal/fleet-assign-employees-modal.component';
import {
  FleetSetsModalComponent,
  FleetSetsModalData,
} from '../components/fleet-sets-modal/fleet-sets-modal.component';
import { FleetCategory } from '../enums/fleet-category';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { FleetState } from '../enums/fleet-state';
import { Fleet, SimplifiedFleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';

@Injectable({
  providedIn: 'root',
})
export class FleetHelper {
  private readonly I18N = 'fleet.fleetList';

  constructor(
    private fleetService: FleetService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private modalService: OnyxModalService,
    private router: Router,
    private translateService: TranslateService,
    private toastService: OnyxToastService,
  ) {}

  public getVehiclesSource(options?: {
    setsOnly?: boolean;
  }): OnyxDropdownOptionsSource<Fleet> {
    return {
      list: (query, limit) =>
        this.fleetService.searchFleet(query, limit, {
          category: [
            FleetCategory.SEMI_TRUCK,
            FleetCategory.STRAIGHT_TRUCK,
            FleetCategory.VAN,
          ],
          state: FleetState.ACTIVE,
          showSetsOnly: options?.setsOnly ?? false,
        }),
      get: (uuid) =>
        this.fleetService.getFleet(uuid).pipe(
          map((vehicle) => ({
            name: this.fleetIdentifierPipe.transform(vehicle),
            value: vehicle,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getTrailersSource(): OnyxDropdownOptionsSource<Fleet> {
    return {
      list: (query, limit) =>
        this.fleetService.searchFleet(query, limit, {
          category: [FleetCategory.SEMI_TRAILER, FleetCategory.TRAILER],
          state: FleetState.ACTIVE,
        }),
      get: (uuid) =>
        this.fleetService.getFleet(uuid).pipe(
          map((trailer) => ({
            name: this.fleetIdentifierPipe.transform(trailer),
            value: trailer,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    fleet: Fleet,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const [isArchived, isVehicle] = [
      FleetHelper.isArchived(fleet),
      FleetHelper.isVehicle(fleet),
    ];

    return [
      {
        options: [
          {
            name: 'buttons.edit',
            value: () => this.edit(fleet.uuid, options),
          },
        ],
      },
      {
        options: [
          {
            name: `${this.I18N}.duplicate`,
            value: () => this.duplicateFleet(fleet, options),
            leftIcon: { name: 'copy' as const, size: 12 },
            leftIconColor: 'blue' as const,
          },
        ],
      },
      ...(!isArchived
        ? [
            {
              options: [
                {
                  name: 'fleet.services.scheduleService',
                  value: () => this.scheduleService(fleet.uuid, options),
                },
                {
                  name: 'fleet.fleetModal.switchVehicle',
                  value: () =>
                    this.modalService.open<FleetSetsModalData>(
                      FleetSetsModalComponent,
                      fleet,
                    ),
                },
                ...(isVehicle
                  ? [
                      {
                        name: `${this.I18N}.assignEmployees`,
                        value: () => this.assignEmployees(fleet),
                      },
                    ]
                  : []),
                ...(isVehicle
                  ? [
                      {
                        name: `${this.I18N}.assignDrivers`,
                        value: () =>
                          this.modalService.open<FleetAssignDriverModalData>(
                            FleetAssignDriverModalComponent,
                            fleet,
                          ),
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        options: [
          ...(fleet.assignedEmployees?.length !== 0 && isVehicle
            ? [
                {
                  name: `${this.I18N}.unassignEmployees`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignEmployees`,
                          )} ${this.fleetIdentifierPipe.transform(fleet)}?`,
                          confirmButtonText: `${this.I18N}.unassignEmployees`,
                        },
                      )
                      .subscribe((ok) => {
                        if (!ok) return;

                        this.unassignEmployees(
                          fleet.uuid,
                          fleet.assignedEmployees?.length ?? 0,
                        );
                      }),
                },
              ]
            : []),
          ...(fleet.drivers?.primaryDriver || fleet.drivers?.secondaryDriver
            ? [
                {
                  name: `${this.I18N}.unassignDrivers`,
                  value: () =>
                    this.modalService
                      .open<OnyxConfirmModalData, boolean>(
                        OnyxConfirmModalComponent,
                        {
                          heading: `${this.translateService.instant(
                            `${this.I18N}.sureToUnassignDrivers`,
                          )} ${this.fleetIdentifierPipe.transform(fleet)}?`,
                          confirmButtonText: `${this.I18N}.unassignDrivers`,
                        },
                      )
                      .subscribe((ok) => {
                        if (!ok) return;

                        this.unassignDrivers(fleet.uuid, fleet.drivers);
                      }),
                },
              ]
            : []),
          ...(fleet.generalInformation.state === FleetState.ARCHIVED
            ? [
                {
                  name: 'buttons.delete',
                  value: () => this.delete(fleet, options),
                },
              ]
            : []),
        ],
      },
      {
        options: [
          {
            name: isArchived ? 'buttons.unarchive' : 'buttons.archive',
            value: () => this.toggleArchived(fleet, options),
          },
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(fleet: FleetModalData): void {
    this.modalService.open<FleetModalData>(FleetModalComponent, fleet);
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.EDIT_FLEET.replace(':uuid', uuid));
    CommonHelper.handleOptions(uuid, options);
  }

  public scheduleService(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.FLEET_SERVICE.replace(':uuid', uuid));
    CommonHelper.handleOptions(uuid, options);
  }

  public assignEmployees(fleet: Fleet | Fleet[]): void {
    const fleetItems = isArray(fleet) ? fleet : [fleet];
    this.modalService.open<FleetAssignEmployeesModalData>(
      FleetAssignEmployeesModalComponent,
      fleetItems,
    );
  }

  public toggleArchived(
    fleet: Fleet | Fleet[],
    options: HelperOptions,
    force?: boolean,
  ): void {
    const items = chain(fleet)
      .thru((fleet) => (isArray(fleet) ? fleet : [fleet]))
      .groupBy((fleet) => force ?? !FleetHelper.isArchived(fleet))
      .thru((groups) => ({
        archive: groups['true'] ?? [],
        unarchive: groups['false'] ?? [],
      }))
      .value();
    const showConfirmModal = items.archive.some(
      (fleet) =>
        fleet.trailer ||
        fleet.vehicle ||
        fleet.assignedEmployees?.length ||
        fleet.drivers,
    );

    const toggle$ = (items: Fleet[], force: boolean): Observable<void> => {
      if (!items.length) return of(undefined);

      return this.fleetService
        .batchFleet(
          items.map((item) => item.uuid),
          { state: force ? FleetState.ARCHIVED : FleetState.ACTIVE },
        )
        .pipe(
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return EMPTY;
          }),
          tap(() =>
            this.toastService.showSuccess(
              this.translateService.instant(
                `fleet.toasts.${items.length === 1 ? 'vehicle' : 'vehicles'}${force ? 'Archived' : 'Activated'}`,
                { count: items.length },
              ),
            ),
          ),
        );
    };

    of(items)
      .pipe(
        switchMap((items) => {
          if (showConfirmModal) {
            return this.modalService
              .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
                heading: this.translateService.instant(
                  `${this.I18N}.sureToArchive${items.archive.length === 1 ? 'Vehicle' : 'Vehicles'}`,
                  {
                    vehicle: this.fleetIdentifierPipe.transform(
                      items.archive[0],
                    ),
                  },
                ),
                badges:
                  items.archive.length > 1
                    ? items.archive.map((fleet) => ({
                        value: this.fleetIdentifierPipe.transform(fleet),
                        color: 'outlined-black',
                      }))
                    : undefined,
                message: `${this.I18N}.sureToContinueArchive`,
                confirmButtonText: 'buttons.archive',
              })
              .pipe(switchMap((ok) => (ok ? of(items) : EMPTY)));
          }
          return of(items);
        }),
        switchMap((items) =>
          forkJoin([
            toggle$(items.archive, true),
            toggle$(items.unarchive, false),
          ]),
        ),
      )
      .subscribe(() => CommonHelper.handleOptions(fleet, options));
  }

  private duplicateFleet(fleet: Fleet, options: HelperOptions): void {
    this.router.navigateByUrl(FleetRoute.ADD_FLEET, {
      state: {
        [FleetRouterStateKey.CATEGORY]: fleet.generalInformation.category,
        [FleetRouterStateKey.TYPE]: fleet.generalInformation.type,
        [FleetRouterStateKey.TEMPLATE]: fleet,
      },
    });
    CommonHelper.handleOptions(fleet.uuid, options);
  }

  private delete(fleet: Fleet, options: HelperOptions): void {
    this.modalService
      .open<OnyxConfirmModalData, boolean>(OnyxConfirmModalComponent, {
        heading: this.translateService.instant('fleet.delete.heading', {
          fleet: this.fleetIdentifierPipe.transform(fleet),
        }),
        message: 'fleet.delete.message',
        confirmButtonText: 'buttons.delete',
        confirmButtonColor: 'red',
      })
      .subscribe((ok) => {
        if (!ok) return;

        this.fleetService.deleteFleet(fleet).subscribe({
          next: () => {
            this.toastService.showSuccess(
              `fleet.toasts.delete.${fleet.generalInformation.category}`,
            );
            CommonHelper.handleOptions(fleet, options);
          },
          error: (response) =>
            ValidationHelper.handleUnexpectedError(response, this.toastService),
        });
      });
  }

  private unassignEmployees(uuid: string, count: number): void {
    this.fleetService.assignEmployees(uuid, { employees: [] }).subscribe({
      next: () =>
        this.toastService.showSuccess(
          this.translateService.instant('fleet.toasts.employeesUnassigned', {
            count,
          }),
        ),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  private unassignDrivers(uuid: string, drivers: Fleet['drivers']): void {
    const requests = Object.values(drivers!)
      .filter((item) => item != null)
      .map((driver, index, drivers) => {
        const isFirst = index === 0;
        const isSingle = drivers.length === 1;

        return this.fleetService.unassignDrivers(
          uuid,
          driver.uuid,
          isSingle || !isFirst,
        );
      });
    if (!requests.length) return;

    from(requests)
      .pipe(
        concatMap((unassignDriver) => unassignDriver),
        last(),
      )
      .subscribe({
        next: () =>
          this.toastService.showSuccess(
            this.translateService.instant('fleet.toasts.driversUnassigned', {
              count: requests.length,
            }),
          ),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      });
  }

  public static isVehicle(fleet: Fleet | SimplifiedFleet): boolean {
    return this.getType(fleet) === 'vehicle';
  }

  public static isTrailer(fleet: Fleet | SimplifiedFleet): boolean {
    return this.getType(fleet) === 'trailer';
  }

  public static isArchived(
    fleet: Fleet | SimplifiedFleet | FleetState,
  ): boolean {
    const state = isString(fleet) ? fleet : fleet.generalInformation.state;
    return state === FleetState.ARCHIVED;
  }

  public static isFleetType(
    vehicle: Fleet | SimplifiedFleet,
  ): vehicle is Fleet {
    return 'status' in vehicle;
  }

  public static fromDto(dto: Fleet): FleetForm {
    return {
      ...dto,
      generalInformation: {
        ...dto.generalInformation,
        assignedBase: dto.generalInformation.assignedBase?.uuid ?? null,
      },
      fuelTankCapacity: dto.fuelTankCapacity as FleetForm['fuelTankCapacity'],
      ownership: {
        ...dto.ownership,
        vehicleValue: dto.ownership
          .vehicleValue as FleetForm['ownership']['vehicleValue'],
        leasingProfile: null,
        loanProfile: null,
        rentalProfile: null,
        selfDeposit: dto.ownership
          .selfDeposit as FleetForm['ownership']['selfDeposit'],
        buyout: dto.ownership.buyout as FleetForm['ownership']['buyout'],
        installment: dto.ownership
          .installment as FleetForm['ownership']['installment'],
      },
      licensesAndPermits:
        dto.licensesAndPermits as FleetForm['licensesAndPermits'],
      additionalParameters: {
        ...dto.additionalParameters,
        temperatureRange: dto.additionalParameters
          .temperatureRange as FleetForm['additionalParameters']['temperatureRange'],
      },
      note: {
        content: dto.note?.content ?? null,
      },
      tachograph: dto.tachograph as FleetForm['tachograph'],
      registrationCertificate:
        dto.registrationCertificate as FleetForm['registrationCertificate'],
      co2Emissions: dto.co2Emissions as FleetForm['co2Emissions'],
      comprehensiveInsurance:
        dto.comprehensiveInsurance as FleetForm['comprehensiveInsurance'],
    };
  }

  private static getType(
    initial:
      | PickDeep<Fleet | SimplifiedFleet, 'generalInformation.category'>
      | Pick<
          Fleet['generalInformation'] | SimplifiedFleet['generalInformation'],
          'category'
        >,
  ): 'vehicle' | 'trailer' {
    const { category } =
      'generalInformation' in initial ? initial.generalInformation : initial;

    return {
      [FleetCategory.SEMI_TRAILER]: 'trailer',
      [FleetCategory.SEMI_TRUCK]: 'vehicle',
      [FleetCategory.STRAIGHT_TRUCK]: 'vehicle',
      [FleetCategory.TRAILER]: 'trailer',
      [FleetCategory.VAN]: 'vehicle',
    }[category] as 'vehicle' | 'trailer';
  }
}
