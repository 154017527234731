@let showClose = data.showClose ?? true;
<onyx-modal
  type="center"
  size="s"
  [heading]="data.heading | translate"
  [showClose]="showClose"
  [close$]="close$"
  (closeModal)="dialogRef.close($event)"
  (keydown.escape)="showClose ? close$.next(undefined) : null"
>
  <div class="container">
    @if (data.badges; as badges) {
      <div class="badges">
        @for (badge of badges; track badge.value) {
          <onyx-badge [color]="badge.color">
            {{ badge.value | translate }}
          </onyx-badge>
        }
      </div>
    }

    {{ data.message || '' | translate }}

    @if (data.templateRef) {
      <ng-container *ngTemplateOutlet="data.templateRef"></ng-container>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next(false)">
      {{ data.cancelButtonText ?? I18N + '.common.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      [color]="$any(data.confirmButtonColor) ?? 'blue'"
      (click)="close$.next(true)"
    >
      {{ data.confirmButtonText | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
