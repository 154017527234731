@let companyProfile = contractor()?.companyProfile ?? null;
<onyx-modal
  type="right"
  [heading]="companyProfile?.displayName ?? ''"
  [loading]="loading()"
  [(error)]="error"
  [roles]="companyProfile?.types ?? []"
  [dictionaryCode]="DictionaryCode.CONTRACTOR_TYPE"
  [close$]="close$"
  [avatarCompany]="{ logo: contractor()?.logo }"
  (errorChange)="contractor$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container leftOptions>
    @if (contractor(); as contractor) {
      <onyx-icon
        class="verified"
        name="verified"
        [size]="16"
        [onyxTooltip]="I18N + '.contractorVerified' | translate"
      ></onyx-icon>

      <p class="description">
        {{ 'labels.vatId' | translate }}:
        {{ companyProfile!.vatId }}
      </p>
    }
  </ng-container>

  <ng-container rightOptions>
    @if (contractor(); as contractor) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        [onyxTooltip]="'labels.options' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @let isActive = contractor.status.value === ContractorStatus.ACTIVE;
      <onyx-icon-button
        color="black"
        type="outlined"
        size="m"
        [circle]="true"
        (click)="isActive ? block() : unblock()"
        [onyxTooltip]="
          (isActive ? 'buttons.block' : 'buttons.unblock') | translate
        "
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        [onyxTooltipPositions]="[OnyxOverlayPosition.BOTTOM]"
        [onyxTooltipOverridePositions]="{ panelClass: ['bottom'] }"
      >
        <onyx-icon
          [name]="isActive ? 'locked' : 'unlocked'"
          [size]="16"
        ></onyx-icon>
      </onyx-icon-button>

      @if (!isArchived()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          [onyxTooltip]="'buttons.edit' | translate"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        [onyxTooltip]="'buttons.call' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="ActionHelper.makeCall(companyProfile!.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  @if (contractor(); as contractor) {
    <div class="container">
      <onyx-information-heading>
        {{ 'labels.payments' | translate }}
      </onyx-information-heading>

      <onyx-information-row [label]="'labels.usedTradeCredit' | translate">
        N/A
      </onyx-information-row>

      <onyx-information-row [label]="'labels.daysPaymentTerms' | translate">
        {{
          'daysNumber'
            | onyxPluralTranslate: contractor.payments.paymentTermDays
            | translate: { days: contractor.payments.paymentTermDays }
        }}
      </onyx-information-row>

      <onyx-information-row [label]="'labels.timeliness' | translate">
        N/A
      </onyx-information-row>

      <onyx-information-heading [count]="contractor.contactPersons.length || 0">
        {{ 'labels.contactPersons' | translate }}
      </onyx-information-heading>

      @for (person of contractor.contactPersons; track person) {
        <onyx-information-row
          class="contact-persons"
          [label]="
            DictionaryCode.EMPLOYEE_ROLE + '.' + person.roles[0] | translate
          "
        >
          <div class="contact-person">
            <p class="name">{{ person.firstName }} {{ person.lastName }}</p>
            <p>{{ person.email }}</p>
            <p>{{ person.phone | onyxPhone }}</p>
          </div>

          <div class="contact-person-buttons">
            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="ActionHelper.makeCall(person.phone)"
            >
              <onyx-icon name="telephone" [size]="16"> </onyx-icon>
            </onyx-icon-button>

            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="ActionHelper.sendEmail(person.email)"
            >
              <onyx-icon name="envelope" [size]="16"> </onyx-icon>
            </onyx-icon-button>
          </div>
        </onyx-information-row>
      }

      <div class="chips">
        <onyx-chips
          [chips]="GROUP_CHIPS"
          type="secondary"
          [single]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @let address = contractor.addresses;
      @switch (group()[0]) {
        @case (ContractorModalSection.ORDERS) {
          <p>N/A</p>
        }

        @case (ContractorModalSection.ADDRESSES) {
          <div class="addresses-container">
            <div class="company-addresses">
              <onyx-information-row [label]="'labels.mainAddress' | translate">
                <app-address
                  [address]="address.mainAddress"
                  format="full"
                  [showFlag]="false"
                ></app-address>
              </onyx-information-row>

              @let correspondenceAddress = address.correspondenceAddress;
              @if (correspondenceAddress) {
                <onyx-information-row
                  [label]="'labels.correspondenceAddress' | translate"
                >
                  <app-address
                    [address]="correspondenceAddress"
                    format="full"
                    [showFlag]="false"
                  ></app-address>
                </onyx-information-row>
              }

              @let branches = contractor.branches;
              @if (branches) {
                @for (branch of branches; track branch.name) {
                  <onyx-information-row [label]="'labels.branch' | translate">
                    <div class="branch">
                      <p>{{ branch.name }}</p>

                      @let vatIdentification = branch.vatIdentification;
                      @if (vatIdentification) {
                        <span>
                          {{ vatIdentification.country | uppercase }}
                          {{ vatIdentification.vatId }}
                        </span>
                      }

                      <app-address
                        [address]="branch.correspondenceAddress"
                        format="full"
                        [showFlag]="false"
                      ></app-address>
                    </div>
                  </onyx-information-row>
                }
              }
            </div>

            <onyx-map></onyx-map>
          </div>
        }

        @case (ContractorModalSection.DOCUMENTS) {
          <onyx-table
            [data]="contractor.documents"
            [columns]="COLUMNS"
            tableSize="s"
            [rowSize]="48"
            [tableShadow]="false"
          ></onyx-table>
        }

        @case (ContractorModalSection.COMPANY) {
          <onyx-information-row [label]="'labels.companyName' | translate">
            {{ companyProfile!.companyName }}
          </onyx-information-row>
        }
      }
    </div>
  }
</onyx-modal>
