import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SimplifiedDriver } from '../../../../../drivers/common/interfaces/driver';

@Component({
  selector: 'app-base-driver-cell',
  imports: [],
  templateUrl: './base-driver-cell.component.html',
  styleUrl: './base-driver-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDriverCellComponent {
  public driver = input.required<SimplifiedDriver | null>();
}
