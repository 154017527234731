@let driverData = driver.driverData;
<onyx-modal
  type="center"
  size="m"
  heading="{{ driverData.firstName }} {{ driverData.lastName }}"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    <form [formGroup]="form">
      <div class="item">
        <onyx-dropdown
          formControlName="vehicle"
          [label]="'labels.vehicle' | translate"
          [options]="VEHICLES_SOURCE"
          [optional]="true"
          (selectedValueChange)="vehicle$.next($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!vehicle$.getValue()"
          (click)="openFleetModal(vehicle$.getValue())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>

      <div class="item">
        <onyx-dropdown
          formControlName="trailer"
          [label]="'labels.trailer' | translate"
          [options]="TRAILERS_SOURCE"
          (selectedValueChange)="trailer.set($event)"
        ></onyx-dropdown>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          [disabled]="!trailer()"
          (click)="openFleetModal(trailer())"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>
    </form>

    @let existingPrimaryDriverData = existingPrimaryDriver()?.driverData;
    @if (
      existingPrimaryDriverData && existingPrimaryDriver()?.uuid !== driver.uuid
    ) {
      <onyx-message size="s" type="warning" [borderRadius]="true">
        <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

        <p>
          {{ I18N + '.toVehicle' | translate }}

          <span class="f-medium-2">
            {{ vehicle$.getValue() | fleetIdentifier }}
          </span>

          {{ I18N + '.currentAssign' | translate }}

          <span class="f-medium-2">
            {{ existingPrimaryDriverData.firstName }}
            {{ existingPrimaryDriverData.lastName }}.
          </span>

          <br />

          <span class="f-medium-2">
            {{ driverData.firstName }} {{ driverData.lastName }}
          </span>

          {{ I18N + '.secondDriver' | translate }}
        </p>
      </onyx-message>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.assign' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
