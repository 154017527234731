import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxTimePipe } from '@onyx/angular';
import { PointOfInterest } from '../../interfaces/point-of-interest';

@Component({
  selector: 'app-points-of-interest-opening-hours-cell',
  imports: [OnyxTimePipe, TranslatePipe],
  templateUrl: './points-of-interest-opening-hours-cell.component.html',
  styleUrl: './points-of-interest-opening-hours-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsOfInterestOpeningHoursCellComponent {
  public businessHours = input.required<
    PointOfInterest['businessHours'] | null
  >();
}
