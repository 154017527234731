import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Inject,
  Injector,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  AddressHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxAddress,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapMarkerType,
  OnyxModalComponent,
  OnyxPhone,
  OnyxPhonePipe,
  OnyxTableComponent,
  OnyxTimePipe,
  OnyxToastService,
  OnyxTooltipDirective,
  PhoneHelper,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { AddressComponent } from '../../../../../../common/components/address/address.component';
import { SHORT_PAGINATION } from '../../../../../../common/constants/short-pagination';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DriverHelper } from '../../../../../drivers/common/helpers/driver.helper';
import { SimplifiedDriver } from '../../../../../drivers/common/interfaces/driver';
import { FleetHelper } from '../../../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../../../fleet/common/interfaces/fleet';
import { BASE_ASSIGNED_DRIVERS_LIST_COLUMNS } from '../../constants/base-assigned-drivers-list-columns';
import { BASE_ASSIGNED_DRIVERS_NOT_FOUND } from '../../constants/base-assigned-drivers-not-found';
import { BASE_ASSIGNED_VEHICLES_LIST_COLUMNS } from '../../constants/base-assigned-vehicles-list-columns';
import { BASE_ASSIGNED_VEHICLES_NOT_FOUND } from '../../constants/base-assigned-vehicles-not-found';
import { getBaseChips } from '../../constants/base-chips';
import { BaseChip } from '../../enums/base-chip';
import { BaseHelper } from '../../helpers/base.helper';
import { Base, SimplifiedBase } from '../../interfaces/base';
import { BasesService } from '../../services/bases.service';

export type BaseModalData = Base | SimplifiedBase | string;

@Component({
  selector: 'app-base-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxMapComponent,
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxTimePipe,
    OnyxPhonePipe,
    OnyxChipsComponent,
    OnyxDropdownDirective,
    OnyxTableComponent,
    AddressComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './base-modal.component.html',
  styleUrl: './base-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseModalComponent {
  protected readonly I18N = 'pointsOfInterest';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly BASE_ASSIGNED_VEHICLES_COLUMNS =
    BASE_ASSIGNED_VEHICLES_LIST_COLUMNS;
  protected readonly BASE_ASSIGNED_DRIVERS_COLUMNS =
    BASE_ASSIGNED_DRIVERS_LIST_COLUMNS;
  protected readonly BASE_ASSIGNED_DRIVERS_NOT_FOUND =
    BASE_ASSIGNED_DRIVERS_NOT_FOUND;
  protected readonly BASE_ASSIGNED_VEHICLES_NOT_FOUND =
    BASE_ASSIGNED_VEHICLES_NOT_FOUND;

  protected readonly ActionHelper = ActionHelper;
  protected readonly AddressHelper = AddressHelper;
  protected readonly PhoneHelper = PhoneHelper;
  protected readonly BaseChip = BaseChip;

  private readonly map = viewChild.required(OnyxMapComponent);
  private readonly uuid: string;

  protected base = signal<Base | null>(null);
  protected chips = computed(() =>
    getBaseChips(
      this.base()?.assignedVehicles?.length,
      this.base()?.assignedDrivers?.length,
    ),
  );
  protected group = signal([this.chips()[0].value]);
  protected loading = signal(false);
  protected error = signal(false);
  protected pagination = signal(SHORT_PAGINATION);
  protected close$ = new Subject<void>();
  protected base$ = new Subject<void>();

  protected options = computed(() => {
    const base = this.base();
    return base
      ? this.baseHelper.getOptions(base, { close$: this.close$ })
      : null;
  });

  constructor(
    @Inject(DIALOG_DATA) protected baseData: BaseModalData,
    protected dialogRef: DialogRef<void>,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private basesService: BasesService,
    private injector: Injector,
    private fleetHelper: FleetHelper,
    private driverHelper: DriverHelper,
    private baseHelper: BaseHelper,
  ) {
    this.uuid = isString(this.baseData) ? this.baseData : this.baseData.uuid;

    this.base$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.basesService.getBase(this.uuid).pipe(
            catchError((response) => {
              this.base.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (base) => {
          this.base.set(base);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (isString(this.baseData) || !BaseHelper.isBaseType(this.baseData)) {
      this.base$.next();
    } else {
      this.base.set(this.baseData);
    }

    this.basesService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.base$.next());

    effect(() => {
      const base = this.base();
      if (!base) return;

      afterNextRender(
        () => {
          this.map().dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: this.uuid,
              type: OnyxMapMarkerType.POINT_OF_INTEREST,
              coordinates: base.address.coordinates,
            }),
          );
          this.map().dispatch(new OnyxMapEvent.FitContent());
        },
        { injector: this.injector },
      );
    });
  }

  protected edit(): void {
    this.baseHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected copyAddress(address: OnyxAddress): void {
    ActionHelper.copy(AddressHelper.composeLabel(address), this.toastService);
  }

  protected copyCoordinates(address: OnyxAddress): void {
    ActionHelper.copy(
      AddressHelper.composeCoordinatesLabel(address),
      this.toastService,
    );
  }

  protected copyPhone(phone: OnyxPhone): void {
    ActionHelper.copy(PhoneHelper.composeLabel(phone), this.toastService);
  }

  protected openDriverModal(driver: SimplifiedDriver): void {
    this.driverHelper.openModal(driver);
  }

  protected openVehicleModal(vehicle: SimplifiedFleet): void {
    this.fleetHelper.openModal(vehicle);
  }
}
