import { TranslateService } from '@ngx-translate/core';
import { chain, isArray, isString } from 'lodash';
import { MaybeArray } from 'simple-git/dist/src/lib/types';
import { Fleet } from '../../dashboard/fleet/common/interfaces/fleet';
import { Order } from '../../dashboard/orders/common/interfaces/order';
import { HelperOptions } from '../interfaces/utilities/helper-options';

export class CommonHelper {
  public static handleOptions(
    items: MaybeArray<{ uuid: string } | string>,
    options: HelperOptions,
  ): void {
    const itemsUuid = new Set(
      chain(items)
        .thru((items) => (isArray(items) ? items : [items]))
        .map((item) => (isString(item) ? item : item.uuid))
        .value(),
    );

    options?.selectedItems?.update((items) =>
      items.filter(({ uuid }) => !itemsUuid.has(uuid)),
    );
    options?.close$?.next();
  }

  public static getSortedParameters(
    parameters: Fleet['additionalParameters'] | Order['parameters'],
    translateService: TranslateService,
  ) {
    return chain(parameters)
      .entries()
      .map(([key, value]) => ({ key, value }))
      .filter(
        ({ value }) =>
          value != null &&
          value !== false &&
          (isArray(value) ? value.length !== 0 : true),
      )
      .map(({ key, value }) => ({
        key,
        name: `labels.${key}`,
        value: value as NonNullable<typeof value>,
      }))
      .orderBy((parameter) =>
        translateService.instant(parameter.name).toLowerCase(),
      )
      .value();
  }
}
