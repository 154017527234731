import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { OnyxDropdownComponent, OnyxOption } from '@onyx/angular';
import { round } from 'lodash';
import { DateTime } from 'luxon';
import { WEEKDAYS } from '../../../../../common/constants/weekdays';
import { DelegationStatus } from '../../../common/enums/delegation-status';
import { Delegation } from '../../../common/interfaces/delegation';

interface RealizationData {
  heading: string;
  content: string;
  isPast: boolean;
}

@Component({
  selector: 'app-delegation-modal-realization-status',
  imports: [NgClass, OnyxDropdownComponent, ReactiveFormsModule, TranslatePipe],
  templateUrl: './delegation-modal-realization-status.component.html',
  styleUrl: './delegation-modal-realization-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalRealizationStatusComponent implements OnInit {
  protected readonly I18N = 'delegations.delegationModal';

  protected readonly DelegationStatus = DelegationStatus;

  private readonly TODAY = DateTime.now().startOf('day');
  private readonly TIME_FORMAT = 'HH:mm:ss';

  public delegation = input.required<Delegation>();

  protected selectedDate = signal(this.TODAY);

  protected dailyReturnsDropdownOptions = computed(() => {
    const { start, end, daysOfWeek } = this.delegation();
    if (!end?.date || !start?.date || !daysOfWeek) return null;

    const options: OnyxOption<DateTime>[] = [];
    const endDay = DateTime.fromISO(end.date).startOf('day');

    let currentDay = DateTime.fromISO(start.date).startOf('day');
    while (currentDay <= endDay) {
      const isWorkingDay = daysOfWeek[WEEKDAYS[currentDay.weekday - 1]];

      if (isWorkingDay) {
        options.push({
          name: currentDay.hasSame(this.TODAY, 'day')
            ? this.translateService.instant('labels.today')
            : currentDay.toFormat('dd.MM'),
          value: currentDay,
        });
      }
      currentDay = currentDay.plus({ days: 1 });
    }

    return options;
  });

  protected realizationData = computed<RealizationData | null>(() => {
    const { start, end, status, hasDailyReturns } = this.delegation();
    const startDate = DateTime.fromISO(`${start.date}T${start.time}`);
    const endDate = end.date
      ? DateTime.fromISO(`${end.date}T${end.time}`)
      : null;

    if (status.value === DelegationStatus.PLANNED) {
      return this.getPlannedStartData(startDate);
    } else if (status.value === DelegationStatus.CANCELED) {
      return null;
    } else if (hasDailyReturns) {
      return this.getDailyReturnsData(start, end);
    } else if (endDate) {
      return this.getProgressData(startDate, endDate);
    }

    return this.getDuringData(startDate);
  });

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    const { start, daysOfWeek, end } = this.delegation();
    const startDate = DateTime.fromISO(start.date).startOf('day');
    const endDate = DateTime.fromISO(end.date!).startOf('day');
    const todayOption = this.dailyReturnsDropdownOptions()?.find((option) =>
      option.value.hasSame(this.TODAY, 'day'),
    );

    let firstDate = startDate;
    while (!daysOfWeek![WEEKDAYS[firstDate.weekday - 1]]) {
      firstDate = firstDate.plus({ days: 1 });

      if (firstDate > endDate) {
        firstDate = startDate;
        break;
      }
    }

    this.selectedDate.set((todayOption?.value ?? firstDate) as DateTime<true>);
  }

  private getPlannedStartData(startDate: DateTime): RealizationData {
    const diff = startDate.diffNow(['days', 'hours', 'minutes']);
    const days = Math.abs(diff.days);
    const hours = Math.abs(round(diff.hours));
    const minutes = Math.abs(round(diff.minutes));

    const content = days
      ? `${days}d ${hours ? `${hours}h` : ''}`
      : hours
        ? `${hours}h ${minutes}m`
        : `${minutes}m`;

    return {
      heading: `${this.I18N}.plannedStart`,
      content,
      isPast: diff.toMillis() < 0,
    };
  }

  private getDailyReturnsData(
    start: Delegation['start'],
    end: Delegation['end'],
  ): RealizationData {
    const selectedDay = this.selectedDate().startOf('day');
    const startTime = DateTime.fromFormat(start.time, this.TIME_FORMAT);
    const endTime = DateTime.fromFormat(end.time!, this.TIME_FORMAT);
    const totalHours = endTime.diff(startTime, 'hours').hours;

    let workedHours = 0;
    if (selectedDay < this.TODAY) {
      workedHours = totalHours;
    } else if (selectedDay.hasSame(this.TODAY, 'day')) {
      workedHours = Math.max(
        0,
        Math.floor(DateTime.now().diff(startTime, ['hours']).hours),
      );
    }

    return {
      heading: 'delegations.dailyReturnsForm.heading',
      content: `${workedHours}h ${this.translateService.instant(`${this.I18N}.from`)} ${totalHours}h`,
      isPast: false,
    };
  }

  private getProgressData(
    startDate: DateTime,
    endDate: DateTime,
  ): RealizationData {
    const totalDays = Math.ceil(endDate.diff(startDate, 'days').days);
    const completedDays = Math.floor(
      DateTime.now().diff(startDate, 'days').days,
    );

    return {
      heading: `${this.I18N}.realized`,
      content: `${completedDays} ${this.translateService.instant(`${this.I18N}.from`)} ${totalDays}d`,
      isPast: completedDays > totalDays,
    };
  }

  private getDuringData(startDate: DateTime): RealizationData {
    const daysElapsed = Math.floor(DateTime.now().diff(startDate, 'days').days);

    return {
      heading: `${this.I18N}.during`,
      content: `${daysElapsed}d`,
      isPast: false,
    };
  }
}
