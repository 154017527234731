import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxTimepickerTime } from '../components';

export const onyxMaxTimeValidator =
  (max: DateTime | OnyxTimepickerTime = DateTime.now()): ValidatorFn =>
  (control: AbstractControl<OnyxTimepickerTime>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    const time = DateTime.fromFormat(value, 'HH:mm:ss');
    const maxTime = isString(max) ? DateTime.fromFormat(max, 'HH:mm:ss') : max;

    return time > maxTime ? { maxTime: maxTime.toFormat('HH:mm') } : null;
  };
