import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';
import { OnyxTimepickerTime, OnyxTimepickerTimeRange } from '../components';

@Pipe({
  name: 'onyxTime',
})
export class OnyxTimePipe implements PipeTransform {
  public transform(
    value: OnyxTimepickerTime | OnyxTimepickerTimeRange,
  ): string {
    if (isString(value)) return this.formatTime(value);

    const from = this.formatTime(value.from);
    const to = this.formatTime(value.to);

    return `${from} - ${to}`;
  }

  private formatTime(time: OnyxTimepickerTime): string {
    return time.slice(0, 5);
  }
}
