import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationRowComponent,
  OnyxModalComponent,
  OnyxPhone,
  OnyxPhonePipe,
  OnyxToastService,
  OnyxTooltipDirective,
  PhoneHelper,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { FleetIdentifierPipe } from '../../../common/components/pipes/fleet-identifier.pipe';
import { UnavailabilitiesComponent } from '../../../common/components/unavailabilities/unavailabilities.component';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { FleetHelper } from '../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../fleet/common/interfaces/fleet';
import { DriverHelper } from '../common/helpers/driver.helper';
import { Driver, SimplifiedDriver } from '../common/interfaces/driver';
import { DriversService } from '../common/services/drivers.service';
import { DriverModalDataComponent } from './driver-modal-data/driver-modal-data.component';
import { DriverModalDocumentsComponent } from './driver-modal-documents/driver-modal-documents.component';

export interface DriverModalData {
  driver: Driver | SimplifiedDriver | string;
  size: 'm' | 's';
}

enum DriverModalSection {
  WORK_TIME = 'work-time',
  DELEGATION = 'delegation',
  STATISTICS = 'statistics',
  UNAVAILABILITIES = 'unavailabilities',
  DRIVER_DATA = 'driver-data',
  DOCUMENTS = 'documents',
  NOTE = 'note',
}

@Component({
  selector: 'app-driver-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxChipsComponent,
    OnyxPhonePipe,
    DriverModalDataComponent,
    DriverModalDocumentsComponent,
    UnavailabilitiesComponent,
    OnyxDropdownDirective,
    FleetIdentifierPipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './driver-modal.component.html',
  styleUrl: './driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalComponent {
  protected readonly I18N = 'drivers.driverModal';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly GROUP_CHIPS: OnyxChip<string>[] = [
    { name: 'labels.workTime', value: DriverModalSection.WORK_TIME },
    { name: 'labels.delegation', value: DriverModalSection.DELEGATION },
    { name: 'labels.statistics', value: DriverModalSection.STATISTICS },
    {
      name: 'labels.unavailabilities',
      value: DriverModalSection.UNAVAILABILITIES,
    },
    { name: 'labels.driverData', value: DriverModalSection.DRIVER_DATA },
    { name: 'labels.documents', value: DriverModalSection.DOCUMENTS },
    { name: 'labels.note', value: DriverModalSection.NOTE },
  ];

  protected readonly ActionHelper = ActionHelper;
  protected readonly DriverModalSection = DriverModalSection;

  private readonly uuid: string;

  protected driver = signal<Driver | null>(null);
  protected group = signal([DriverModalSection.WORK_TIME]);
  protected loading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected driver$ = new Subject<void>();

  protected options = computed(() => {
    const driver = this.driver();
    return driver
      ? this.driverHelper.getOptions(driver, { close$: this.close$ })
      : null;
  });
  protected isArchived = computed(() => {
    const driver = this.driver();
    return driver ? DriverHelper.isArchived(driver) : null;
  });

  constructor(
    @Inject(DIALOG_DATA)
    protected driverData: DriverModalData,
    protected dialogRef: DialogRef<void>,
    private driversService: DriversService,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private driverHelper: DriverHelper,
    private fleetHelper: FleetHelper,
  ) {
    this.uuid = isString(this.driverData.driver)
      ? this.driverData.driver
      : this.driverData.driver.uuid;

    this.driver$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.driversService.getDriver(this.uuid).pipe(
            catchError((response) => {
              this.driver.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (driver) => {
          this.driver.set(driver);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (
      isString(this.driverData.driver) ||
      !DriverHelper.isDriverType(this.driverData.driver)
    ) {
      this.driver$.next();
    } else {
      this.driver.set(this.driverData.driver);
    }

    this.driversService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.driver$.next());
  }

  protected edit(): void {
    this.driverHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected openVehicleModal(vehicle: SimplifiedFleet): void {
    this.fleetHelper.openModal(vehicle);
  }

  protected copyPhone(phone: OnyxPhone) {
    ActionHelper.copy(PhoneHelper.composeLabel(phone), this.toastService);
  }

  protected copy(value: string) {
    ActionHelper.copy(value, this.toastService);
  }
}
