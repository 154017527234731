<onyx-modal
  type="right"
  [heading]="pointOfInterest()?.name ?? ''"
  [loading]="loading()"
  [(error)]="error"
  [close$]="close$"
  (errorChange)="pointOfInterest$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    @if (pointOfInterest(); as pointOfInterest) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        [onyxTooltip]="'labels.options' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        color="black"
        type="outlined"
        size="m"
        [circle]="true"
        [onyxTooltip]="'buttons.edit' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="edit()"
      >
        <onyx-icon name="edit" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        [onyxTooltip]="'buttons.call' | translate"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="ActionHelper.makeCall(pointOfInterest.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  @if (pointOfInterest(); as pointOfInterest) {
    <div class="container">
      <onyx-map [defer]="true"></onyx-map>

      <onyx-information-heading>
        {{ 'labels.location' | translate }}
      </onyx-information-heading>

      <onyx-information-row [label]="'labels.address' | translate">
        <app-address
          [address]="pointOfInterest.address"
          format="short"
        ></app-address>

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyAddress(pointOfInterest.address)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>

      <onyx-information-row [label]="'labels.coordinates' | translate">
        <app-address
          [address]="pointOfInterest.address"
          format="coordinates"
        ></app-address>

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyCoordinates(pointOfInterest.address)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.openingHours' | translate }}
      </onyx-information-heading>

      <ng-container>
        @let businessHours = pointOfInterest.businessHours;
        <onyx-information-row [label]="'labels.weekday' | translate">
          {{ businessHours.weekday | onyxTime }}
        </onyx-information-row>

        <onyx-information-row [label]="'labels.saturday' | translate">
          {{
            businessHours.saturday
              ? (businessHours.saturday | onyxTime)
              : ('labels.closed' | translate)
          }}
        </onyx-information-row>

        <onyx-information-row [label]="'labels.sunday' | translate">
          {{
            businessHours.sunday
              ? (businessHours.sunday | onyxTime)
              : ('labels.closed' | translate)
          }}
        </onyx-information-row>
      </ng-container>

      <onyx-information-heading>
        {{ 'labels.times' | translate }}
      </onyx-information-heading>

      <onyx-information-row [label]="'labels.averageServiceTime' | translate">
        {{ pointOfInterest.time.averageServiceTime | onyxTime }}
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.contactData' | translate }}
      </onyx-information-heading>

      <onyx-information-row [label]="'labels.phone' | translate">
        {{ pointOfInterest.phone | onyxPhone }}

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyPhone(pointOfInterest.phone)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>

      <div class="chips">
        <onyx-chips
          [chips]="chips()"
          [single]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        @case (PointOfInterestModalSection.REALIZATIONS) {
          <onyx-spinner></onyx-spinner>
        }
        @case (PointOfInterestModalSection.ASSIGNED_CONTRACTORS) {
          <onyx-table
            [data]="pointOfInterest.contractors"
            [columns]="COLUMNS"
            tableSize="s"
            [tableShadow]="false"
            [rowSize]="48"
            [rowOptions]="getContractorOptions.bind(this)"
            [(pagination)]="pagination"
            [notFound]="NOT_FOUND"
          ></onyx-table>
        }
      }

      <onyx-information-heading>
        {{ 'labels.note' | translate }}
      </onyx-information-heading>

      <p class="f-medium-2">{{ pointOfInterest.note }}</p>
    </div>
  }
</onyx-modal>
