import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  DropdownHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isEqual, pick } from 'lodash';
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  ReplaySubject,
  startWith,
  Subject,
} from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { DriverHelper } from '../../../../drivers/common/helpers/driver.helper';
import { Driver } from '../../../../drivers/common/interfaces/driver';
import { DriverCategory } from '../../../../drivers/common/interfaces/driver-category';
import { DriversService } from '../../../../drivers/common/services/drivers.service';
import { Fleet, SimplifiedFleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

export type FleetAssignDriverModalData = Fleet;

type FleetAssignDriverModalFormGroup = ReturnType<
  FleetAssignDriverModalComponent['buildForm']
>;

export type FleetAssignDriverModalForm = ReturnType<
  FleetAssignDriverModalFormGroup['getRawValue']
>;

@Component({
  selector: 'app-fleet-assign-driver-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxIconComponent,
    FleetIdentifierPipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxMessageComponent,
  ],
  templateUrl: './fleet-assign-driver-modal.component.html',
  styleUrl: './fleet-assign-driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetAssignDriverModalComponent implements OnInit {
  protected readonly I18N = 'fleet.assignDriversModal';
  protected readonly DRIVERS_SOURCE: OnyxDropdownOptionsSource<Driver> = {
    list: (query, limit) =>
      this.driversService
        .searchDrivers(query, limit, {
          category: DriverCategory.ACTIVE,
        })
        .pipe(
          combineLatestWith(this.driversChanges$.pipe(startWith(undefined))),
          map(([result]) => ({
            ...result,
            options: DropdownHelper.updateGroupOptions(
              result.options,
              (options) =>
                options.filter(({ value }) => {
                  const drivers = chain(this.form.getRawValue())
                    .pick(['primaryDriverUuid', 'secondaryDriverUuid'])
                    .values()
                    .compact()
                    .value();
                  return !drivers.includes(value.uuid);
                }),
            ),
          })),
        ),
    get: (uuid) =>
      this.driversService.getDriver(uuid).pipe(
        map((driver) => ({
          name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
          value: driver,
        })),
      ),
    idKey: 'uuid',
  };

  protected form = this.buildForm();
  protected loading = signal(false);
  protected primaryDriver = signal<Driver | null>(null);
  protected secondaryDriver = signal<Driver | null>(null);
  protected formChanges = toSignal(
    this.form.valueChanges.pipe(map(() => this.form.getRawValue())),
  );
  protected close$ = new Subject<void>();

  protected swappedData = computed(() =>
    chain([this.primaryDriver(), this.secondaryDriver()])
      .compact()
      .filter(
        (driver) =>
          !!driver.assignedVehicle &&
          driver.assignedVehicle.uuid !== this.fleet.uuid,
      )
      .map((driver) => ({
        driver,
        vehicle: driver.assignedVehicle as SimplifiedFleet,
      }))
      .value(),
  );

  private driversChanges$ = new ReplaySubject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected fleet: FleetAssignDriverModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private driversService: DriversService,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private modalService: OnyxModalService,
    private driverHelper: DriverHelper,
  ) {}

  public ngOnInit(): void {
    const { primaryDriver, secondaryDriver } = this.fleet.drivers ?? {};
    this.form.setValue({
      primaryDriverUuid: primaryDriver?.uuid ?? null,
      secondaryDriverUuid: secondaryDriver?.uuid ?? null,
    });

    this.form.valueChanges
      .pipe(
        map(() =>
          pick(this.form.getRawValue(), [
            'primaryDriverUuid',
            'secondaryDriverUuid',
          ]),
        ),
        distinctUntilChanged(isEqual),
      )
      .subscribe(() => this.driversChanges$.next());

    this.form.controls.primaryDriverUuid.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) =>
        ValidationHelper.toggleControls(
          this.form.controls.secondaryDriverUuid,
          !!value,
        ),
      );
  }

  protected openDriverModal(driver: Driver | null): void {
    if (driver) this.driverHelper.openModal(driver);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    this.loading.set(true);
    this.fleetService
      .assignDrivers(form, this.fleet.uuid)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.success`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      primaryDriverUuid: this.fb.control<string | null | undefined>(null, [
        Validators.required,
      ]),
      secondaryDriverUuid: this.fb.control<string | null | undefined>({
        value: null,
        disabled: true,
      }),
    });
  }
}
