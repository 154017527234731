<div class="order-modal-heading">
  <div class="item">
    <span class="gray">{{ 'labels.drivers' | translate }}</span>

    @for (driver of drivers(); track driver.uuid) {
      @let driverData = driver.driverData;
      <span class="driver">
        <onyx-link
          color="black"
          font="f-regular-2"
          (click)="openDriverModal(driver)"
        >
          {{ driverData.firstName }} {{ driverData.lastName }}
        </onyx-link>

        {{ !$last ? ',' : '' }}
      </span>
    }
  </div>

  <!-- TEMP: Delete variable after backend implementation -->
  @if (author(); as author) {
    <span>·</span>

    <div class="item">
      <span class="gray">{{ 'labels.author' | translate }}</span>

      <span>{{ author.firstName }} {{ author.lastName }}</span>
    </div>
  }
</div>
