<onyx-modal
  type="center"
  size="s"
  [heading]="I18N + '.heading' | translate"
  [close$]="close$"
  (keydown.enter)="next()"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    <onyx-dropdown
      formControlName="country"
      [label]="'labels.country' | translate"
      [options]="vatIdCountries$ | async"
      [search]="true"
      width="80px"
    ></onyx-dropdown>

    <onyx-text-field
      formControlName="vatId"
      [label]="'labels.vatId' | translate"
    ></onyx-text-field>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? next() : null"
    >
      {{ 'buttons.continue' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

<ng-template #conflictToastTemplate>
  <p>{{ I18N + '.contractorExists' | translate }}</p>

  <onyx-button size="s" (click)="showExistingContractor()">
    {{ 'buttons.show' | translate }}
  </onyx-button>
</ng-template>
