<div class="container">
  @let registrationCertificate = vehicle().registrationCertificate;
  <app-modal-documents-heading
    heading="labels.technicalInspection"
    [scans]="registrationCertificate.scan"
  ></app-modal-documents-heading>

  <app-modal-date-row
    label="labels.expirationDate"
    [expirationDate]="registrationCertificate.technicalInspectionExpiryDate"
    size="m"
  ></app-modal-date-row>

  @let co2Emissions = vehicle().co2Emissions;
  @if (co2Emissions) {
    <app-modal-documents-heading
      heading="fleet.fleetForm.co2Emissions.heading"
      [scans]="co2Emissions.emissionCertificate"
    ></app-modal-documents-heading>

    <onyx-information-row [label]="'labels.emissionStandard' | translate">
      {{
        DictionaryCode.EMISSION_STANDARD + '.' + co2Emissions.emissionStandard
          | translate
      }}
    </onyx-information-row>
  }

  @let thirdPartyLiabilityInsurance = vehicle().thirdPartyLiabilityInsurance;
  <app-modal-documents-heading
    heading="labels.oc"
    [scans]="thirdPartyLiabilityInsurance.contractScan"
  ></app-modal-documents-heading>

  <onyx-information-row [label]="'labels.insuranceNumber' | translate">
    {{ thirdPartyLiabilityInsurance.insuranceNumber }}
  </onyx-information-row>

  <app-modal-date-row
    label="labels.expirationDate"
    [expirationDate]="thirdPartyLiabilityInsurance.expiryDate"
    size="m"
  ></app-modal-date-row>

  <onyx-information-row [label]="'labels.annualCost' | translate">
    <app-amount-cell [amount]="thirdPartyLiabilityInsurance.annualCost">
    </app-amount-cell>
  </onyx-information-row>

  <onyx-information-row [label]="'labels.insuranceSum' | translate">
    <app-amount-cell [amount]="thirdPartyLiabilityInsurance.insuranceSum">
    </app-amount-cell>
  </onyx-information-row>

  @let comprehensiveInsurance = vehicle().comprehensiveInsurance;
  @if (comprehensiveInsurance) {
    <app-modal-documents-heading
      heading="labels.ac"
      [scans]="[comprehensiveInsurance.contractScan]"
    ></app-modal-documents-heading>

    <onyx-information-row [label]="'labels.insuranceNumber' | translate">
      {{ comprehensiveInsurance.insuranceNumber }}
    </onyx-information-row>

    <app-modal-date-row
      label="labels.expirationDate"
      [expirationDate]="comprehensiveInsurance.expiryDate"
      size="m"
    ></app-modal-date-row>

    <onyx-information-row [label]="'labels.annualCost' | translate">
      <app-amount-cell [amount]="comprehensiveInsurance.annualCost">
      </app-amount-cell>
    </onyx-information-row>

    <onyx-information-row [label]="'labels.insuranceSum' | translate">
      <app-amount-cell [amount]="comprehensiveInsurance.insuranceSum">
      </app-amount-cell>
    </onyx-information-row>
  }
</div>
