import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  DropdownHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxToastService,
} from '@onyx/angular';
import { chain } from 'lodash';
import { BehaviorSubject, map, of, Subject, switchMap } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';
import { FleetState } from '../../../../fleet/common/enums/fleet-state';
import { FleetHelper } from '../../../../fleet/common/helpers/fleet.helper';
import { Fleet } from '../../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../../fleet/common/services/fleet.service';
import { Driver } from '../../interfaces/driver';
import { DriversService } from '../../services/drivers.service';

export type DriverAssignVehicleModalData = Driver;

@Component({
  selector: 'app-driver-assign-vehicle-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxMessageComponent,
    OnyxIconComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
  ],
  templateUrl: './driver-assign-vehicle-modal.component.html',
  styleUrl: './driver-assign-vehicle-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverAssignVehicleModalComponent implements OnInit {
  protected readonly I18N = 'drivers.assignVehicleModal';
  protected readonly VEHICLES_SOURCE: OnyxDropdownOptionsSource<Fleet> = {
    list: (query, limit) =>
      this.fleetService
        .searchFleet(query, limit, {
          category: [
            FleetCategory.SEMI_TRUCK,
            FleetCategory.STRAIGHT_TRUCK,
            FleetCategory.VAN,
          ],
          state: FleetState.ACTIVE,
        })
        .pipe(
          map((result) => ({
            ...result,
            options: DropdownHelper.updateGroupOptions(
              result.options,
              (options) =>
                options.map((option) => {
                  const isDisabled =
                    chain(option.value.drivers)
                      .values()
                      .compact()
                      .filter((driver) => driver.uuid !== this.driver.uuid)
                      .value().length === 2;

                  return {
                    ...option,
                    ...(isDisabled && {
                      disabled: true,
                      rightIcon: { name: 'block', size: 16 },
                      tooltip: `${this.I18N}.fullVehicle`,
                    }),
                  };
                }),
            ),
          })),
        ),
    get: (uuid) =>
      this.fleetService.getFleet(uuid).pipe(
        map((vehicle) => ({
          name: this.fleetIdentifierPipe.transform(vehicle),
          value: vehicle,
        })),
      ),
    idKey: 'uuid',
  };
  protected readonly TRAILERS_SOURCE = this.fleetHelper.getTrailersSource();

  protected form = this.buildForm();
  protected loading = signal(false);
  protected vehicle$ = new BehaviorSubject<Fleet | null>(null);
  protected trailer = signal<Fleet | null>(null);
  protected close$ = new Subject<void>();
  protected existingPrimaryDriver = signal<Driver | null>(null);

  constructor(
    @Inject(DIALOG_DATA) protected driver: DriverAssignVehicleModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private driversService: DriversService,
    private destroyRef: DestroyRef,
    private fleetHelper: FleetHelper,
    private fleetIdentifierPipe: FleetIdentifierPipe,
  ) {
    this.vehicle$
      .pipe(
        map((vehicle) => {
          const trailerControl = this.form.controls.trailer;
          if (!vehicle || !vehicle?.trailer) {
            trailerControl.setValue(null);
            return null;
          }

          trailerControl.setValue(vehicle.trailer.uuid);
          return vehicle;
        }),
        switchMap((vehicle) => {
          if (!vehicle || !vehicle.drivers) return of(null);
          const { primaryDriver } = vehicle.drivers;

          if (primaryDriver) {
            return this.driversService.getDriver(primaryDriver.uuid);
          }
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((existingPrimaryDriver) =>
        this.existingPrimaryDriver.set(existingPrimaryDriver),
      );
  }

  public ngOnInit(): void {
    this.form.patchValue({
      vehicle: this.driver.assignedVehicle?.uuid ?? null,
    });
  }

  protected openFleetModal(fleet: Fleet | null): void {
    if (fleet) this.fleetHelper.openModal(fleet);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const body = this.existingPrimaryDriver()
      ? {
          primaryDriverUuid: this.existingPrimaryDriver()?.uuid,
          secondaryDriverUuid: this.driver.uuid,
        }
      : { primaryDriverUuid: this.driver.uuid, secondaryDriverUuid: null };

    this.loading.set(true);
    this.fleetService
      .assignDrivers(body, form.vehicle!)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.success`);
          this.close$.next();
          this.driversService.reload();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      vehicle: this.fb.control<string | null>(null, [Validators.required]),
      trailer: this.fb.control<string | null>({ value: null, disabled: true }),
    });
  }
}
