<div class="container">
  @if (label(); as label) {
    <onyx-input-label
      [label]="label"
      [hint]="hint()"
      [optional]="isOptional() && showOptional()"
      [link]="link()"
      [disabled]="disabled()"
      (click)="focus()"
    ></onyx-input-label>
  }

  <div
    class="dropdown {{ size() }}"
    [ngClass]="{
      ribbon: ribbon(),
      transparent: transparentRibbon(),
      expanded: expanded(),
      active: selectedNames().length,
      disabled: disabled(),
      invalid: isInvalid(),
    }"
    [tabIndex]="disabled() ? -1 : 0"
    [onyxDropdownOptions]="filteredOptions$"
    [onyxDropdownSelectedOptions]="selectedOptions()"
    [onyxDropdownCompareKey]="$any(compareKey())"
    [onyxDropdownOptional]="optional()"
    [onyxDropdownMultiple]="multiple()"
    [onyxDropdownDisabled]="disabled()"
    [(onyxDropdownAttached)]="expanded"
    [onyxDropdownSearch]="search() || isSource(options())"
    [onyxDropdownSearchPlaceholder]="searchPlaceholder()"
    [(onyxDropdownQuery)]="query"
    [onyxDropdownNotFoundMessage]="notFoundMessage()"
    [onyxDropdownShowAddOption]="showAddOption()"
    [onyxDropdownAddOptionMessage]="addOptionMessage()"
    [onyxDropdownSubheading]="subheading()"
    [onyxDropdownSubheadingTemplateRef]="subheadingTemplateRef()"
    [onyxDropdownOptionTemplateRef]="optionTemplateRef()"
    [onyxDropdownOptionSize]="optionSize()"
    [onyxDropdownWidth]="overlayWidth()"
    (focusout)="onTouched?.()"
    (onyxDropdownSelectedOptionsChange)="changeSelectedOptions($event)"
    (onyxDropdownAddOption)="addOption.emit($event)"
    #dropdownElement
  >
    @if (ribbon()) {
      <div class="ribbon-icon">
        <ng-content select="[ribbonIcon]"></ng-content>
      </div>
    }

    @let showPlaceholder = !selectedNames().length;
    <div
      class="output"
      [ngClass]="{
        placeholder: showPlaceholder,
        'f-medium-1': ribbon(),
      }"
      [onyxTextOverflow]="
        showPlaceholder || (ribbon() && !transparentRibbon())
          ? undefined
          : selectedNames()
      "
      [onyxTextOverflowPlaceholder]="placeholder() | translate"
      [onyxTextOverflowElementRef]="dropdownElement"
    ></div>

    <div class="actions">
      <div class="hidden">
        @if (
          !disabled() &&
          !ribbon() &&
          selectedNames().length &&
          (optional() || multiple())
        ) {
          <onyx-clear-button
            (click)="clearValue(); $event.stopPropagation()"
          ></onyx-clear-button>
        }
      </div>
    </div>

    <onyx-icon class="arrow" name="arrow-alt-down" [size]="16"></onyx-icon>
  </div>

  <ng-content></ng-content>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [formControls]="[formControl()!]"
  ></onyx-form-control-error>
}
